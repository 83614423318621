import React from 'react'
import { injectIntl } from 'gatsby-plugin-react-intl'
import SectionWrapper from '@components/ui/wrappers/SectionWrapper'
import styled from 'styled-components'
import { HeadingH1, DescriptionDisplay1 } from '@components/ui/typography/Typography'
import UnderlineHeading from '@components/common/UnderlineHeading'

const LifetimeHeroSection = ({ intl }) => {
  return (
    <SectionWrapper sectionBackground="#EFF4FF">
      <Wrapper>
        <HeadingH1>
          {intl.formatMessage({ id: 'lifetime-deal.hero.get' })}
          <UnderlineHeading
            text={intl.formatMessage({ id: 'lifetime-deal.hero.titleUnderline' })}
            margin="0 10px"
            height="50px"
            xlHeight="62px"
          />
          {intl.formatMessage({ id: 'lifetime-deal.hero.title' })}
        </HeadingH1>
        <DescriptionWrapper>
          <DescriptionDisplay1>{intl.formatMessage({ id: 'lifetime-deal.hero.description' })}</DescriptionDisplay1>
        </DescriptionWrapper>
      </Wrapper>
    </SectionWrapper>
  )
}

export default injectIntl(LifetimeHeroSection)

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  max-width: 600px;
`
