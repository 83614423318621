import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { injectIntl } from 'gatsby-plugin-react-intl'
import LifetimePlanCard from '../../../components/pages/lifetime-deal/LifetimePlanCard'
import { usedPlanIds } from '@configs/plans'
import { formatPrice } from '@configs/currency'

const LifetimePlanCards = ({ intl, period }) => {
  const data = useStaticQuery(graphql`
    query {
      allPlanCardsJson {
        edges {
          node {
            slug
            status
            sticker {
              publicURL
            }
            title
            description
            buttonText
            disabledFeatures
            lifetimePriceOptions
          }
        }
      }
    }
  `)

  const [cardValues, setCardValues] = useState({
    monthly: {
      freePlan: 0,
      dreamer: 1,
      founder: 1,
      team: 0,
      teamPro: 1,
    },
    annual: {
      freePlan: 0,
      dreamer: 1,
      founder: 1,
      team: 0,
      teamPro: 1,
    },
    lifetime: {
      freePlan: 0,
      dreamer: 1,
      founder: 1,
      team: 0,
      teamPro: 1,
    },
  })

  const [paddle, setPaddle] = useState(null)
  const [prices, setPrices] = useState(null)

  useEffect(() => {
    const getPaddle = setInterval(() => {
      if (!paddle) {
        setPaddle(window.Paddle)
      }

      return
    }, 0)

    return () => clearInterval(getPaddle)
  }, [paddle])

  useEffect(() => {
    const setPaddleSandbox = () => {
      if (process.env.PADDLE_SANDBOX === 'true') {
        paddle.Environment.set('sandbox')
      }
    }
    const getPrices = () => {
      let plansPrices = []

      usedPlanIds.forEach(({ value, name, aiRequests }) => {
        paddle.Product.Prices(value, (prices) => {
          const { currency, price } = formatPrice(prices.price.net)

          plansPrices.push({ name: name, price, currency, aiRequests })

          if (plansPrices.length === usedPlanIds.length) {
            setPrices(plansPrices)
          }
        })
      })
    }

    if (!prices && paddle) {
      setPaddleSandbox()
      getPrices()
    }
  }, [prices, paddle])

  const findPriceByName = (name) => prices && prices.find((x) => x.name === name).price
  const findCurrencyByName = (name) => prices && prices.find((x) => x.name === name).currency

  const findAiRequestsByName = (name) => prices && prices.find((x) => x.name === name)?.aiRequests
  const onChangeNoi = (noi, planType) => {
    setCardValues({
      ...cardValues,
      [period]: {
        ...cardValues[period],
        [planType]: noi,
      },
    })
  }

  const getPrice = (item) => {
    const numberOfIdeas = cardValues[period][item.slug]

    return item.slug === 'freePlan' || item.slug === 'team'
      ? null
      : findPriceByName(`${item.slug}_${numberOfIdeas}_${period}`)
  }

  const getCurrency = (item) => {
    const numberOfIdeas = cardValues[period][item.slug]

    return item.slug === 'freePlan' || item.slug === 'team'
      ? null
      : findCurrencyByName(`${item.slug}_${numberOfIdeas}_${period}`)
  }

  const getAiRequests = (item) => {
    const numberOfIdeas = cardValues[period][item.slug]

    return item.slug === 'freePlan' || item.slug === 'team'
      ? null
      : findAiRequestsByName(`${item.slug}_${numberOfIdeas}_${period}`)
  }

  return (
    <PricingCardsWrapper>
      {data.allPlanCardsJson.edges.map((item, index) => {
        return (
          item.node.slug === 'founder' && (
            <LifetimePlanCard
              key={index}
              slug={item.node.slug}
              status={item.node.status}
              sticker={item.node.sticker ? item.node.sticker.publicURL : null}
              title={intl.formatMessage({ id: item.node.title })}
              description={intl.formatMessage({ id: item.node.description })}
              price={getPrice(item.node)}
              currency={getCurrency(item.node)}
              period={period}
              buttonText={intl.formatMessage({ id: item.node.buttonText })}
              disabledFeatures={item.node.disabledFeatures}
              noi={cardValues[period][item.node.slug]}
              priceOptions={item.node.lifetimePriceOptions}
              aiRequests={getAiRequests(item.node)}
              onChangeNoi={(noi) => {
                onChangeNoi(noi, item.node.slug)
              }}
            />
          )
        )
      })}
    </PricingCardsWrapper>
  )
}

export default injectIntl(LifetimePlanCards)

const PricingCardsWrapper = styled.div``
