import React, { useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import SectionWrapper from '@components/ui/wrappers/SectionWrapper'
import TextVideo from '@components/common/TextVideo'
import { injectIntl } from 'gatsby-plugin-react-intl'
import SectionHeader from '@components/ui/typography/SectionHeader'
import getGlobalDocument from '@configs/getGlobalDocument'

const LifetimeTextVideoSection = ({ intl }) => {
  const data = useStaticQuery(graphql`
    query {
      allTextVideoLifetimeJson {
        edges {
          node {
            slug
            reverse
            tabName
            tabIcon {
              publicURL
            }
            color
            title
            description
            video {
              mp4 {
                publicURL
              }
              ogv {
                publicURL
              }
              webm {
                publicURL
              }
            }
          }
        }
      }
    }
  `)
  useEffect(() => {
    let videos = getGlobalDocument().querySelectorAll('video')

    if (videos.length) {
      videos.forEach((video) => {
        let observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              entry.isIntersecting ? entry.target.play() : entry.target.pause()
            })
          },
          { threshold: 1 },
        )
        observer.observe(video)
      })
    }
  }, [])

  return (
    <SectionWrapper>
      <SectionHeader
        title={intl.formatMessage({ id: 'lifetime-deal.textVideo.title' })}
        description={intl.formatMessage({ id: 'lifetime-deal.textVideo.description' })}
      />
      {data.allTextVideoLifetimeJson.edges.map((item, index) => {
        return (
          <div key={index}>
            <TextVideo
              slug={item.node.slug}
              color={item.node.color}
              reverse={item.node.reverse}
              tabIcon={item.node.tabIcon}
              tabName={intl.formatMessage({ id: item.node.tabName })}
              title={intl.formatMessage({ id: item.node.title })}
              description={intl.formatMessage({ id: item.node.description })}
              video={item.node.video}
            />
          </div>
        )
      })}
    </SectionWrapper>
  )
}

export default injectIntl(LifetimeTextVideoSection)
