import React from 'react'
import styled from 'styled-components'
import { color, breakpoint } from '@configs/utilities'

const TextVideo = (props) => {
  return (
    <Wrapper reverse={props.reverse}>
      <TextWrapper>
        <IconText>
          <img src={props.tabIcon.publicURL} alt={props.slug} width={20} loading="lazy" />
          <span style={{ color: props.color }}>{props.tabName}</span>
        </IconText>
        <Title>{props.title}</Title>
        <Description>{props.description}</Description>
      </TextWrapper>
      <VideoWrapper>
        <video width="550px" muted={true} playsInline loop={true} autoPlay={true}>
          <source src={props.video.mp4.publicURL} type="video/mp4" />
          <source src={props.video.ogv.publicURL} type="video/ogv" />
          <source src={props.video.webm.publicURL} type="video/webm" />
          Your browser does not support the video tag.
        </video>
      </VideoWrapper>
    </Wrapper>
  )
}

export default TextVideo

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: ${color.font};
  margin: 50px 0 0;
  row-gap: 10px;

  @media screen and (min-width: ${breakpoint.md}) {
    flex-direction: row;
    column-gap: 100px;
    flex-direction: ${(props) => (props.reverse ? 'row-reverse' : 'row')};
    margin: 50px 0 100px;
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  row-gap: 20px;

  @media screen and (min-width: ${breakpoint.md}) {
    width: 40%;
    max-width: auto;
    align-items: flex-start;
    text-align: left;
  }
`

const IconText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    margin-right: 5px;
  }

  span {
    font-weight: 500;
    font-size: 18px;
  }
`

const Title = styled.p`
  font-weight: 700;
  font-size: 18px;

  @media screen and (min-width: ${breakpoint.md}) {
    max-width: 300px;
    font-size: 22px;
  }

  @media screen and (min-width: ${breakpoint.md}) {
    max-width: 380px;
    font-size: 26px;
  }
`

const Description = styled.p`
  font-weight: 300;
  font-size: 14px;

  @media screen and (min-width: ${breakpoint.md}) {
    font-size: 14px;
    max-width: 340px;
  }

  @media screen and (min-width: ${breakpoint.xl}) {
    font-size: 16px;
    max-width: 320px;
  }
`

const VideoWrapper = styled.div`
  @media screen and (min-width: ${breakpoint.md}) {
    width: 60%;
  }
  video {
    width: 100%;
    border-radius: 8px;
    box-shadow: 0 5px 30px rgba(8, 30, 74, 0.15);
  }
`
