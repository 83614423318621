import React from 'react'
import SectionWrapper from '@components/ui/wrappers/SectionWrapper'
import LifetimePlanCards from '@components/pages/lifetime-deal/LifetimePlanCards'
import YourCountry from '@components/common/YourCountry'

const LifetimeCardSection = () => {
  return (
    <SectionWrapper>
      <LifetimePlanCards period={'lifetime'} />
      <YourCountry />
    </SectionWrapper>
  )
}

export default LifetimeCardSection
