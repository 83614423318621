import React from 'react'
import { injectIntl, Link } from 'gatsby-plugin-react-intl'
import styled from 'styled-components'
import { color, breakpoint, transition, opacity } from '@configs/utilities'
import SectionWrapper from '@components/ui/wrappers/SectionWrapper'

const FooterLight = ({ intl }) => {
  return (
    <SectionWrapper sectionBackground={color.blueDark}>
      <FooterWrapper>
        <FooterBottomSection>
          <FooterBottomIdea>
            <span>
              © IdeaBuddy {new Date().getFullYear()}, {intl.formatMessage({ id: 'footer.ideaBuddy' })}
            </span>
          </FooterBottomIdea>
          <FooterBottomLinks>
            <Link to="/terms-of-service">{intl.formatMessage({ id: 'footer.terms' })}</Link>
            <span>|</span>
            <Link to="/privacy-policy">{intl.formatMessage({ id: 'footer.privacy' })}</Link>
            <span>|</span>
            <Link to="/cookie-statement">{intl.formatMessage({ id: 'footer.cookies' })}</Link>
          </FooterBottomLinks>
        </FooterBottomSection>
      </FooterWrapper>
    </SectionWrapper>
  )
}

export default injectIntl(FooterLight)

/* ---------------------------------------------- BOTTOM SECTION ---------------------------------------------- */

const FooterWrapper = styled.div`
  padding: 0 5px;

  @media screen and (min-width: ${breakpoint.lg}) {
    padding: 0 25px;
  }

  a {
    color: ${color.footerLink};
    font-weight: 300;
    font-size: 14px;
    transition: ${transition.default};
    cursor: pointer;

    @media (hover: hover) {
      &:hover {
        opacity: ${opacity.default};
      }
    }
  }
`

const FooterBottomSection = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  border-top: 1px solid ${color.footerBorder};
  row-gap: 15px;
  padding: 24px 0 0;

  @media screen and (min-width: ${breakpoint.md}) {
    justify-content: space-between;
    flex-direction: row;
  }
`

const FooterBottomIdea = styled.div`
  text-align: center;

  span {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: ${color.white};
  }
`

const FooterBottomLinks = styled.div`
  text-align: center;

  span {
    color: ${color.footerLink};
    margin: 0 10px;
  }
`
