import React, { useState, createContext } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { HelmetProvider, Helmet } from 'react-helmet-async'
import { injectIntl } from 'gatsby-plugin-react-intl'
import Seo from '@components/seo/SEO'
import { ContentWrapper } from '@components/ui/wrappers/Wrappers'
import NavbarLight from './navbarLight/NavbarLight'
import FooterLight from './FooterLight'
import _find from 'lodash/find'
import { ThemeProvider } from '@mui/material/styles'
import muiTheme from '@configs/muiTheme'
import ModalContact from '@components/common/ModalContact'
import getGlobalDocument from '@configs/getGlobalDocument'
// import { CookiesConsent } from '@components/common/CookiesConsent'

export const OpenModalContext = createContext()

const Layout = ({ children, pageName, intl }) => {
  const data = useStaticQuery(graphql`
    query {
      allSeoJson {
        edges {
          node {
            slug
            title
            description
            url
          }
        }
      }
    }
  `)

  const seoData = data.allSeoJson.edges.map((item) => {
    return item.node
  })

  const seoMeta = _find(seoData, ['slug', pageName])

  const [open, setOpen] = useState(false)
  const [type, setType] = useState('')

  const openContact = (type) => {
    setOpen(true)
    setType(type)
    let documentStyle = getGlobalDocument().getElementsByTagName('html')[0]
    documentStyle.style.overflow = 'hidden'
  }

  const handleClose = () => {
    setOpen(false)
    setType('')
    let documentStyle = getGlobalDocument().getElementsByTagName('html')[0]
    documentStyle.style.overflow = 'auto'
  }

  return (
    <HelmetProvider defer={true}>
      <Helmet>
        <script
          id="Cookiebot"
          src="https://consent.cookiebot.com/uc.js"
          data-cbid="4cdb85f8-b43f-4822-8645-c9bf88b0b450"
          type="text/javascript"
        />
        <script
          id="CookieDeclaration"
          src="https://consent.cookiebot.com/4cdb85f8-b43f-4822-8645-c9bf88b0b450/cd.js"
          type="text/javascript"
          async
        />
        <script id="paddlejs" async src="https://cdn.paddle.com/paddle/paddle.js" />
        <script>
          {`(function(p,t,n,e,r,o){ p['__partnerObject']=r;function f(){
            var c={ a:arguments,q:[]};var r=this.push(c);return "number"!=typeof r?r:f.bind(c.q);}
            f.q=f.q||[];p[r]=p[r]||f.bind(f.q);p[r].q=p[r].q||f.q;o=t.createElement(n);
            var _=t.getElementsByTagName(n)[0];o.async=1;o.src=e+'?v'+(~~(new Date().getTime()/1e6));
            _.parentNode.insertBefore(o,_);})(window, document, 'script', 'https://app.partnero.com/js/universal.js', 'po');
            po('settings', 'assets_host', 'https://assets.partnero.com');
            po('program', '${process.env.PARTNERO_PROGRAM_ID}', 'load');`}
        </script>
      </Helmet>
      {seoMeta && (
        <Seo
          title={intl.formatMessage({ id: seoMeta.title })}
          description={intl.formatMessage({ id: seoMeta.description })}
          pathname={seoMeta.url}
        />
      )}
      <ThemeProvider theme={muiTheme}>
        <NavbarLight />
        <div className="overlay"></div>
        <OpenModalContext.Provider value={{ openContact }}>
          <ContentWrapper>
            <ModalContact open={open} type={type} handleClose={handleClose} intl={intl} />
            {children}
          </ContentWrapper>
          <FooterLight />
          {/*<CookiesConsent />*/}
        </OpenModalContext.Provider>
      </ThemeProvider>
    </HelmetProvider>
  )
}

export default injectIntl(Layout)
