import React from 'react'
import { injectIntl } from 'gatsby-plugin-react-intl'
import styled from 'styled-components'

const YourCountry = ({ intl }) => {
  return <Wrapper>{intl.formatMessage({ id: 'pricing.hero.country' })}</Wrapper>
}

export default injectIntl(YourCountry)

const Wrapper = styled.p`
  font-weight: 300;
  font-size: 16px;
  text-align: center;
  margin-top: 50px;
`
