import React from 'react'
import styled from 'styled-components'
import { color, breakpoint } from '@configs/utilities'
import defaultLine from '@assets/images/common/defaultLine.svg'

const UnderlineHeading = (props) => {
  return (
    <UnderlineHeadingWrapper
      display={props.display}
      background={props.background}
      margin={props.margin}
      height={props.height}
      padding={props.padding}
      mdDisplay={props.mdDisplay}
      mdHeight={props.mdHeight}
      mdMargin={props.mdMargin}
      mdPadding={props.mdPadding}
      xlDisplay={props.xlDisplay}
      xlHeight={props.xlHeight}
      xlMargin={props.xlMargin}
      xlPadding={props.xlPadding}
    >
      {props.text}
    </UnderlineHeadingWrapper>
  )
}

export default UnderlineHeading

const UnderlineHeadingWrapper = styled.span`
  box-sizing: border-box;
  width: max-content;
  background: ${(props) => `url(${props.background || defaultLine})`};
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: contain;
  color: ${color.font};
  display: ${(props) => props.display || 'inline-block'};
  height: ${(props) => props.height || '36px'};
  margin: ${(props) => props.margin || '0 auto'};
  padding: ${(props) => props.padding || '0 5px'};

  @media screen and (min-width: ${breakpoint.md}) {
    display: ${(props) => props.mdDisplay || props.display};
    height: ${(props) => props.mdHeight || props.height};
    margin: ${(props) => props.mdMargin || props.margin};
    padding: ${(props) => props.mdPadding || props.padding};
  }

  @media screen and (min-width: ${breakpoint.xl}) {
    display: ${(props) => props.xlDisplay || props.mdDisplay};
    height: ${(props) => props.xlHeight || props.mdHeight};
    margin: ${(props) => props.xlMargin || props.mdMargin};
    padding: ${(props) => props.xlPadding || props.mdPadding};
  }
`
