import React from 'react'
import { Link } from 'gatsby-plugin-react-intl'
import styled from 'styled-components'
import { color, transition } from '@configs/utilities'
import arrow from '@assets/images/common/arrowRight.svg'

const UnderlineLink = ({ text, to, className, color, hideArrow = false }) => {
  return (
    <Wrapper color={color}>
      <Link to={to} className={className}>
        {text}
      </Link>
      {!hideArrow && <img src={arrow} alt="arrow-right" />}
    </Wrapper>
  )
}

export default UnderlineLink

const Wrapper = styled.div`
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  transition: ${transition.default};
  column-gap: 5px;

  a {
    color: ${color.font};
    ${({ color }) =>
      color &&
      `
    border-bottom: 2px solid ${color};
  `}
  }

  a,
  img {
    padding: 0 0 2px;
  }
`
